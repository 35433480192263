import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { REDIRECT_REQUEST, SKIP_ENCODE_URL } from '@core/interceptors/http-context-tokens';
import { Archive } from '@core/models/archive';
import FileSaver from 'file-saver';
import { EMPTY, Observable, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tenant } from '../models/tenant';

@Injectable({
  providedIn: 'root',
})
export class BaseTenantService {
  protected BASE_URL = environment.RH_BASE_URL;

  protected MGMT_BASE_URL = `${environment.RH_BASE_URL}/mgmt/tenants`;

  constructor(protected http: HttpClient) {}

  public getCollectionSize(filters?: string): Observable<number> {
    const queryParams = filters ? `?${filters}` : '';
    return this.http
      .get<{
        _size: number;
      }>(`${this.MGMT_BASE_URL}/_size${queryParams}`, { context: new HttpContext().set(SKIP_ENCODE_URL, true) })
      .pipe(map((resp: { _size: number }) => resp._size));
  }

  public getPage(page = 1, pageSize = 15, filters?: string): Observable<Tenant.Tenant[]> {
    const queryParams =
      filters !== ''
        ? `sort={"_id":1}&pagesize=${pageSize}&page=${page}&${filters}`
        : `pagesize=${pageSize}&page=${page}`;
    return this.http.get<Tenant.Tenant[]>(`${this.MGMT_BASE_URL}?${queryParams}`, {
      context: new HttpContext().set(SKIP_ENCODE_URL, true),
    });
  }

  getAll(sort: string = '{"_id":1}'): Observable<Tenant.Tenant[]> {
    return this.http.get<Tenant.Tenant[]>(`${this.MGMT_BASE_URL}?sort=${sort}&pagesize=1000`);
  }

  public getById(tenantId: string): Observable<Tenant.Tenant> {
    return this.http.get<Tenant.Tenant>(`${this.MGMT_BASE_URL}/${tenantId}`);
  }

  public update(tenantId: string, tenant: Partial<Tenant.Tenant>): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${this.MGMT_BASE_URL}/${tenantId}`, tenant, {
      observe: 'response',
      responseType: 'json',
    });
  }

  uploadStatute(
    file: File,
    options: { tenant: { _id: string } | null; filename: string } = {
      filename: file.name,
      tenant: null,
    }
  ) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('properties', JSON.stringify(options));

    return this.http.put(`${this.BASE_URL}/${options.tenant?._id}/attachments.files/statute`, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }

  downloadStatute(tenantId: string, file: Archive.ArchiveFile) {
    return this.http
      .get(`${this.BASE_URL}/${tenantId}/attachments.files/statute/binary`, {
        responseType: 'blob',
      })
      .pipe(
        switchMap((blob: Blob) => {
          FileSaver(blob, file.filename);
          return EMPTY;
        })
      );
  }

  public removeStatute(tenantId: string): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/${tenantId}/attachments.files/statute`, {
      observe: 'response',
      responseType: 'json',
      context: new HttpContext().set(REDIRECT_REQUEST, false),
    });
  }

  public getTenantStatute(tenantId: string): Observable<any> {
    return this.http.get(`${this.BASE_URL}/${tenantId}/attachments.files/statute`, {
      observe: 'response',
      responseType: 'json',
      context: new HttpContext().set(REDIRECT_REQUEST, false),
    });
  }
}
