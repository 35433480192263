<div class="h-100 d-flex flex-column">
  <!-- TOP SECTION (FILTERS, INVITE, SEARCH) -->
  <app-page-filters-and-cta>
    <app-text-search-filter
      search
      [context]="'user'"
      (searchTextChange)="onSearchChange($event)"></app-text-search-filter>

    <button filters mat-icon-button (click)="openUserPropsFilterDialog()" [color]="filterDialogDirty ? 'warn' : ''">
      <mat-icon>filter_list</mat-icon>
    </button>

    <button add-btn mat-raised-button color="primary" (click)="inviteUser()">
      {{ 'MANAGEMENT.USERS.INVITE_USER' | translate }}
    </button>
  </app-page-filters-and-cta>

  <!-- END TOP SECTION -->

  @if (userList$ | async) {
    <main class="surface-bg">
      <div style="overflow-x: auto">
        <table mat-table [dataSource]="users$" class="w-100" style="min-width: 980px">
          <ng-container matColumnDef="_id">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let user">{{ user._id }}</td>
          </ng-container>
          <ng-container matColumnDef="tenant">
            <th mat-header-cell *matHeaderCellDef>{{ 'users.component.azienda' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.tenants ? (user?.tenants[0] | titlecase) : '' }}</td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'users.component.nome' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.profile?.name }}</td>
          </ng-container>
          <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef>{{ 'users.component.cognome' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.profile?.surname }}</td>
          </ng-container>
          <ng-container matColumnDef="roles">
            <th mat-header-cell *matHeaderCellDef>{{ 'users.component.ruolo' | translate }}</th>
            <td mat-cell *matCellDef="let user">
              {{ joinRoles(user.roles) | async }}
            </td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>{{ 'users.component.telefono' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user?.profile?.phone }}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ 'users.component.azioni' | translate }}</th>
            <td mat-cell *matCellDef="let user">
              <section class="d-flex">
                @if (currentUser$ | async; as currentUser) {
                  @if (currentUser !== user._id) {
                    <button
                      matTooltip="Edit user details"
                      mat-icon-button
                      (click)="openEditDialog(user)"
                      color="accent">
                      <mat-icon>edit</mat-icon>
                    </button>
                  }
                }
                @if(user.pwdAuth) { <!-- only native gets pwdAuth -->
                  <!-- nativa can send activation email and reset email -->
                  <button
                    mat-icon-button
                    color="accent"
                    [disabled]="!canResendEmail"
                    matTooltip="{{ isLockedUser(user.roles) ? ('users.component.invia-email-per-l-at' | translate) : ('users.component.invia-email-per-il-r' | translate) }}"
                    (click)="resendEmail(user._id)">
                    <mat-icon>mail</mat-icon>
                  </button>
                  @if (!user.pwdAuth.verified) {
                    <!-- nativa can also copy activation/reset link -->
                    <button
                      mat-icon-button
                      color="accent"
                      matTooltip=" {{ isLockedUser(user.roles) ? ('users.component.copia-link-di-attiva' | translate) : ('users.component.copia-link-di-reset-' | translate) }}"
                      [cdkCopyToClipboard]="resetOrActivationLink(user)">
                      <mat-icon>content_copy</mat-icon>
                    </button>
                  }
                } @else if (isLockedUser(user.roles)) {
                  <!-- manager users can send activation email again -->
                  <button
                    mat-icon-button
                    color="accent"
                    [disabled]="!canResendEmail"
                    matTooltip="{{ 'users.component.invia-email-per-l-at' | translate }}"
                    (click)="resendEmail(user._id)">
                    <mat-icon>mail</mat-icon>
                  </button>
                }
              </section>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="tableCols"></tr>
          <tr mat-row *matRowDef="let row; columns: tableCols"></tr>
        </table>
      </div>
    </main>
  }

  <!-- PAGINATION -->
  <ng-container>
    <section class="mt-auto d-flex justify-content-center mb-3">
      <mat-paginator
        #paginator
        [pageSize]="pageSize"
        [pageIndex]="0"
        [length]="userCollectionSize"
        (page)="getNextPage($event)"
        [disabled]="isPaginatorDisabled"></mat-paginator>
    </section>
  </ng-container>
</div>
