import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirstChar',
})
export class UppercaseFirstCharPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    if (typeof value !== 'string') {
      throw new Error(`uppercaseFirstChar pipe accepts only strings!`);
    }
    return value.length ? value.charAt(0).toUpperCase() + value.slice(1) : value;
  }
}
