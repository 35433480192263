import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '@core/services/users.service';
import { Observable } from 'rxjs';
import { MgmtTenantService } from 'src/app/admin/management/services/mgmt-tenant.service';
import { Tenant } from 'src/app/core/models/tenant';
import { User } from 'src/app/core/models/users';

@Component({
  selector: 'app-edit-user-details-dialog',
  styles: [':host { display: block; max-width: 600px }'],
  template: `
    <h2 mat-dialog-title class="text-center">{{ 'edit-user-details-dialog.component.modifica-utente' | translate }}</h2>

    @if (tenantList$ | async; as tenants) {
      <div mat-dialog-content class="pt-2">
        <app-user-details
          [user]="data.user"
          [tenants]="tenants"
          (formValueChange)="onFormValueChange($event)"
          (formStateChange)="onFormStateChange($event)"></app-user-details>
      </div>
    }

    <div mat-dialog-actions class="d-flex flex-column align-items-center">
      <div class="my-3">
        <button mat-dialog-close mat-button>{{ 'edit-user-details-dialog.component.annulla' | translate }}</button>
        <button
          [mat-dialog-close]="{ action: 'update', payload: userFormValue }"
          [disabled]="!userFormState"
          mat-raised-button
          color="primary">
          Salva
        </button>
      </div>
      <div class="pb-1">
        <button mat-button color="warn" [matMenuTriggerFor]="menu">
          {{ 'edit-user-details-dialog.component.elimina-definitivame' | translate }}
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="deleteUser()">
            {{ 'edit-user-details-dialog.component.si' | translate }}
          </button>
          <button mat-menu-item>{{ 'edit-user-details-dialog.component.annulla-0' | translate }}</button>
        </mat-menu>
      </div>
    </div>
  `,
})
export class EditUserDetailsDialogComponent implements OnInit {
  tenantList$!: Observable<Tenant.Tenant[]>;

  userFormValue: any;

  userFormState!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User.User },
    private dialogRef: MatDialogRef<EditUserDetailsDialogComponent>,
    private tenantSvc: MgmtTenantService,
    private userSvc: UsersService
  ) {}

  ngOnInit(): void {
    this.tenantList$ = this.tenantSvc.getAll();
  }

  onFormValueChange(value: any) {
    this.userFormValue = value;
  }

  onFormStateChange(value: boolean) {
    this.userFormState = value;
  }

  deleteUser() {
    this.userSvc.destroy(this.data.user._id).subscribe(() => this.dialogRef.close({ action: 'delete' }));
  }
}
