import { createReducer, on } from '@ngrx/store';
import { updateTenantCurrentPage } from '../actions/tenant.actions';

export const tenantsFeatureKey = 'tenants';

export interface State {
  pageIndex: number;
  length?: number;
  pageSize?: number;
  previousPageIndex?: number | undefined;
}

export const initialState: State = {
  pageIndex: 0,
};

export const reducer = createReducer(
  initialState,
  on(updateTenantCurrentPage, (state, page): State => page)
);
