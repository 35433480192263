import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '@core/models/configuration';
import { Observable } from 'rxjs';
import { Tenant } from 'src/app/core/models/tenant';
import { BaseTenantService } from 'src/app/core/services/base-tenant.service';

@Injectable({
  providedIn: 'any',
})
export class MgmtTenantService extends BaseTenantService {
  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * On creation, the backend will correctly initialize all needed collections
   */
  public store(tenant: Tenant.Tenant): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${this.BASE_URL}/initTenant/${tenant._id}`, tenant, {
      observe: 'response',
    });
  }

  public updateConfigurationScope(
    tenantId: string,
    scope: Configuration.ConfigurationScope
  ): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(
      `${this.BASE_URL}/configurationScopeUpdate/${tenantId}`,
      { configurationScope: scope },
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }
}
