import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, take, tap } from 'rxjs';
import { MgmtTenantService } from 'src/app/admin/management/services/mgmt-tenant.service';
import { Tenant } from 'src/app/core/models/tenant';
import { User } from 'src/app/core/models/users';
import { resetUserFilters, updateUserFilters } from 'src/app/core/store/management/actions/user-filters.actions';
import { selectUserFilterFilters } from 'src/app/core/store/management/reducers';

@Component({
  selector: 'app-user-props-component',
  styles: [':host { display: block; min-width: 350px}'],
  template: `
    <h3 mat-dialog-title class="text-center">{{ 'user-props-filter-dialog.component.filtri' | translate }}</h3>
    <form mat-dialog-content [formGroup]="propsGroup" class="pt-1 mb-3">
      <mat-form-field appearance="outline" class="w-100 mb-3">
        <mat-label>{{ 'user-props-filter-dialog.component.ruoli' | translate }}</mat-label>
        <mat-select formControlName="roles" multiple>
          @for (role of roles; track role) {
            <mat-option [value]="role">{{ role }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (isTenantFilterEnabled) {
        <mat-form-field appearance="outline" class="w-100 mb-3">
          <mat-label>{{ 'user-props-filter-dialog.component.aziende' | translate }}</mat-label>
          <mat-select formControlName="tenants" multiple>
            @for (tenant of tenants$ | async; track tenant) {
              <mat-option [value]="tenant._id">{{ tenant._id }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <section formGroupName="status">
        <mat-checkbox formControlName="enabled">{{
          'user-props-filter-dialog.component.abilitato' | translate
        }}</mat-checkbox>
        <mat-checkbox formControlName="disabled" class="ms-3">{{
          'user-props-filter-dialog.component.disabilitato' | translate
        }}</mat-checkbox>
      </section>
    </form>

    <section mat-dialog-actions class="d-flex justify-content-center">
      <button
        mat-icon-button
        (click)="resetFilters()"
        [matTooltip]="'user-props-filter-dialog.component.cancella-i-filtri' | translate">
        <mat-icon>restart_alt</mat-icon>
      </button>
      <button mat-flat-button mat-dialog-close>{{ 'user-props-filter-dialog.component.annulla' | translate }}</button>
      <button mat-raised-button mat-dialog-close (click)="updateStore()">
        {{ 'user-props-filter-dialog.component.conferma' | translate }}
      </button>
    </section>
  `,
})
export class UserPropsFilterDialogComponent implements OnInit, OnDestroy {
  @Input() isTenantFilterEnabled: boolean = true;

  propsGroup!: UntypedFormGroup;

  tenants$!: Observable<Tenant.Tenant[]>;

  roles = Object.values(User.UserRole);

  @Output() dirty = new BehaviorSubject(false);

  sub!: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private tenantSvc: MgmtTenantService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.tenants$ = this.tenantSvc.getAll();

    this.propsGroup = this.fb.group({
      tenants: [{ value: null, disabled: !this.isTenantFilterEnabled }],
      roles: [null],
      status: this.fb.group({
        enabled: [true],
        disabled: [false],
      }),
    });

    this.store
      .select(selectUserFilterFilters)
      .pipe(
        take(1),
        tap(({ roles, tenants, status }) => {
          this.propsGroup.patchValue({
            roles,
            tenants,
            status,
          });
          this.dirty.next(!this.enabled || this.disabled || (tenants && tenants.length > 0) || (roles && roles.length > 0));
        })
      )
      .subscribe();
  }

  updateStore() {
    //this.store.dispatch(updateUserCurrentPage({pageIndex: 0, pageSize: 10, length: 0}));
    this.store.dispatch(updateUserFilters(this.propsGroup.value));
    this.dirty.next(!this.enabled || this.disabled || (this.propsGroup.value.tenants && this.propsGroup.value.tenants.length > 0) || (this.propsGroup.value.roles && this.propsGroup.value.roles.length > 0));
  }

  resetFilters() {
    this.propsGroup.reset({ status: { enabled: true } });
    this.store.dispatch(resetUserFilters());
    this.dirty.next(false);
  }

  get enabled() {
    return (this.propsGroup!.get('status') as UntypedFormGroup)!.get('enabled')!.value;
  }

  get disabled() {
    return (this.propsGroup!.get('status') as UntypedFormGroup)!.get('disabled')!.value;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
