import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  template: `
    <form enctype="multipart/form-data" class="row justify-content-center">
      <section class="col-6 mt-3">
        <div class="d-flex justify-content-center">
          <input #uploader type="file" hidden accept="image/*" (change)="onFileChange($event)" />

          <div class="d-flex justify-content-center">
            <div
              class="my-2"
              id="img-container"
              [style.maxHeight]="imgMaxHeight + 'px'"
              [style.maxWidth]="imgMaxWidth + 'px'">
              <img [src]="image" alt="{{ 'image-uploader.component.carica-il-logo-della' | translate }}" />
              <div class="overlay d-flex justify-content-center align-items-center">
                <div class="action">
                  <button mat-icon-button color="primary" (click)="openPicker()">
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  `,
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  @Input() imgMaxWidth: number = 200;

  @Input() imgMaxHeight: number = 200;

  @Input() showPreview: boolean = false;

  @Input() image!: any;

  @Output() imageChange = new EventEmitter<File>();

  @ViewChild('uploader', { read: ElementRef })
  uploader!: ElementRef<HTMLInputElement>;

  @ViewChild('outOfBoundsTpl', { read: TemplateRef })
  outOfBoundsTpl!: TemplateRef<any>;

  constructor() {}

  onFileChange(data: Event) {
    const element = data.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      const img = fileList[0];
      this.saveImageIfInBoudns(img);
    }
  }

  openPicker() {
    this.uploader.nativeElement.click();
  }

  // private displayPreview() {
  //   if (this.image) {
  //     this.preview.clear();
  //     this.preview.createEmbeddedView(this.imgTpl, {
  //       $implicit: this.image,
  //     });
  //   }
  // }

  private saveImageIfInBoudns(file: File): void {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (rs: any) => {
        // const img_height = rs.currentTarget['height'];
        // const img_width = rs.currentTarget['width'];
        this.imageChange.emit(file);

        // if (img_height < maxHeight && img_width < maxWidth) {
        //   this.imageChange.emit(file);
        //   this.currentImage = file;
        // } else {
        //   this.dialog.open(this.outOfBoundsTpl, {
        //     data: {
        //       maxWidth,
        //       maxHeight,
        //     },
        //   });
        //   console.log('Image is out of bounds...');
        // }
      };
    };
    reader.readAsDataURL(file);
  }
}
