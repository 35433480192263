/* eslint-disable @ngrx/no-store-subscription */
/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@core/models/users';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, combineLatestWith, first, map, startWith, tap } from 'rxjs';
import { selectLang, selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { selectSelectedTenant } from 'src/app/auth/state/selectors/user-tenant.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-invite-dialog',
  template: `
    @if (data.tenants$ | async; as tenants) {
      <h1 mat-dialog-title class="text-center">{{ 'user-invite-dialog.component.invita-un-utente' | translate }}</h1>
      <form mat-dialog-content autocomplete="off" [formGroup]="inviteForm" class="row justify-content-center">
        <mat-form-field appearance="outline" class="col-12 pt-2 mb-3">
          <mat-label>{{ 'user-invite-dialog.component.email-dellutente' | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'user-invite-dialog.component.email-dellutente-che' | translate"
            formControlName="email"
            required />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 mb-3">
          <mat-label>{{ 'user-invite-dialog.component.scegli-i-ruoli' | translate }}</mat-label>
          <mat-select formControlName="role">
            @for (role of _roles | async; track role) {
              <mat-option [value]="role">{{ role | remapRole | translateTranslatableProp | async }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (i18nEnabled) {
          <mat-form-field appearance="outline" class="col-12 mb-3">
            <mat-label>{{ 'user-invite-dialog.component.lingua' | translate }}</mat-label>
            <mat-select formControlName="lang">
              <mat-option value="it">{{ 'user-invite-dialog.component.italiano' | translate }}</mat-option>
              <mat-option value="en">{{ 'user-invite-dialog.component.inglese' | translate }}</mat-option>
              <mat-option value="pt">{{ 'user-invite-dialog.component.portoghese' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        }

        @if (shouldDisplayTenantSelection$ | async) {
          <app-searchable-select class="col-12" formControlName="tenants" placeholderLabel="Cerca" [items]="tenants">
          </app-searchable-select>
        }
      </form>
      <div mat-dialog-actions class="d-flex justify-content-center">
        <button mat-button mat-dialog-close>{{ 'user-invite-dialog.component.annulla' | translate }}</button>
        <button mat-raised-button (click)="getUserInviteData()" [disabled]="!inviteForm.valid" color="primary">
          {{ 'user-invite-dialog.component.invita' | translate }}
        </button>
      </div>
    }
  `,
})
export class InviteUserDialogComponent implements OnInit {
  inviteForm!: UntypedFormGroup;

  i18nEnabled = environment.ENABLE_I18N;

  _roles: Observable<User.UserRole[]> = this.getRoles();

  isCurrentUserNativa$: Observable<boolean> = this.store.select(selectUserRoles).pipe(
    first(roles => !!roles),
    map(roles => (roles ? roles.includes(User.UserRole.NATIVA) : false))
  );

  isNativaRoleSelected$!: Observable<boolean>;

  shouldDisplayTenantSelection$!: Observable<boolean>;

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private dialogRef: MatDialogRef<InviteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tenants$: Observable<string[]> }
  ) {}

  ngOnInit(): void {
    this.store.select(selectLang).subscribe(
      lang =>
        (this.inviteForm = this.fb.group({
          email: ['', [Validators.required, Validators.email]],
          tenants: [null, [Validators.required]],
          lang: [lang, [Validators.required]],
          role: ['user', [Validators.required]],
        }))
    );

    this.isNativaRoleSelected$ = this.inviteForm.get('role')!.valueChanges.pipe(
      map(role => role === User.UserRole.NATIVA),
      startWith(false)
    );

    this.shouldDisplayTenantSelection$ = combineLatest([this.isNativaRoleSelected$, this.isCurrentUserNativa$]).pipe(
      map(([isNativaRoleSelected, isCurrentUserNativa]) => isCurrentUserNativa && !isNativaRoleSelected),
      tap(shouldDisplay => (shouldDisplay ? this.tenants?.enable() : this.tenants?.disable()))
    );
  }

  getUserInviteData(): void {
    const { email, tenants, role, lang } = this.inviteForm.value;
    // if currently logged user is not NATIVA, then tenants array should contain the tenant of the currently logged user
    this.store
      .select(selectUserRoles)
      .pipe(
        first(roles => !!roles),
        map(roles => (roles ? roles.includes(User.UserRole.NATIVA) : false))
      )
      .pipe(
        combineLatestWith(this.store.select(selectSelectedTenant)),
        map(([isCurrentUserNativa, userSelectedTenant]) => {
          if (isCurrentUserNativa) {
            return { email, role, tenants: tenants ? [tenants] : [], lang: lang };
          }
          return { email, role, tenants: [userSelectedTenant?._id], lang: lang };
        })
      )
      .subscribe(data => {
        this.dialogRef.close(data);
      });
  }

  get role(): UntypedFormControl {
    return this.inviteForm.get('role') as UntypedFormControl;
  }

  get tenants(): UntypedFormControl {
    return this.inviteForm.get('tenants') as UntypedFormControl;
  }

  getRoles(): Observable<User.UserRole[]> {
    return this.store.select(selectUserRoles).pipe(
      first(roles => !!roles),
      map(userRoles => {
        const _roles = Object.values(User.UserRole);
        const roles = _roles.filter(role => role !== User.UserRole.LOCKED_USER);
        return !userRoles?.includes(User.UserRole.NATIVA)
          ? roles.filter(role => role !== User.UserRole.NATIVA && role !== User.UserRole.ADMIN)
          : roles.filter(role => role !== User.UserRole.ADMIN);
      })
    );
  }
}
