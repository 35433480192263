<!-- <h1 class="text-center pt-5 pb-4">{{ 'NAV.NEWS' | translate }}</h1> -->
  <div class="pt-4">
  @if (news$ | async; as news) {
    <!-- Featured -->
    @if (featuredNews.length > 0) {
      <section class="mb-5">
        <h3 class="d-inline-block">{{ 'news.component.in-primo-piano' | translate }}</h3>
        <div class="row bg-primary-light justify-content-sm-start">
          @for (n of featuredNews; track n) {
            <div class="news-col col-12 col-md-6 col-xl-4 mb-4">
              <app-news-card [news]="n"></app-news-card>
            </div>
          }
        </div>
      </section>
    }
    <!-- Other news -->
    <section>
      <!-- <h3 class="d-inline-block">Altre notizie</h3> -->
      <div class="row justify-content-sm-start">
        @for (n of otherNews; track n) {
          <div class="news-col col-12 col-md-6 col-xl-4 mb-4">
            <app-news-card [news]="n"></app-news-card>
          </div>
        }
      </div>
    </section>
  }
</div>
