import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'renameFlowId',
})
export class RenameFlowIdPipe implements PipeTransform {
  private flowIdMap = new Map<string, string>();

  constructor() {
    this.flowIdMap.set('flow_intro', 'Flow exponential');
    this.flowIdMap.set('flow_mwm', 'Flow measure what matters');
    this.flowIdMap.set('flow_sb', 'Flow società benefit');
  }

  transform(value: string, ...args: unknown[]): string {
    return this.flowIdMap.get(value) || value;
  }
}
