import { Component } from '@angular/core';

@Component({
  selector: 'app-page-filters-and-cta',
  template: `
    <section class="d-flex flex-wrap justify-content-center header mt-4 mb-2 align-items-center">
      <div class="header-search mb-2">
        <ng-content select="[search]"></ng-content>
        <ng-content select="[filters]"></ng-content>
      </div>
      <div class="header-button mb-2">
        <div class="d-flex justify-content-center">
          <ng-content select="[add-btn]"></ng-content>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-2">
        <ng-content select="[end-elements]"></ng-content>
      </div>
    </section>
  `,
  styles: [
    `
      .header {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
      }

      .header-search {
        grid-column: span 1;
      }

      .header-button {
        grid-column: span 1;
      }

      .header-search {
        display: flex;
        justify-content: center;
        padding-left: 2.3em;
        align-items: center;
      }

      ::ng-deep .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    `,
  ],
})
export class PageFiltersAndCtaComponent {
  constructor() {}
}
