import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  template: `
    <div>
      <input #uploader type="file" [accept]="allowedExtensions" hidden (change)="onFileChange($event)" />
      <button mat-raised-button color="primary" (click)="uploader.click()" [disabled]="isBtnDisabled">
        {{ btnText }}
        <mat-icon>file_upload</mat-icon>
      </button>
    </div>
  `,
  styles: [],
})
export class FileUploaderComponent {
  @ViewChild('uploader') uploader: any;

  @Output() fileChange = new EventEmitter<File>();

  @Input() btnText = 'Upload file';

  @Input() isBtnDisabled: boolean = false;

  @Input() allowedExtensions = ['.xlsx', '.xls', '.csv'];

  constructor() {}

  onFileChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList && fileList.length) {
      const file: File = fileList[0];
      this.fileChange.emit(file);
      this.uploader.nativeElement.value = '';
    }
  }
}
