import { Component, Input } from '@angular/core';
import { isString } from 'lodash';
import { TranslatableValue } from './../../../core/models/utils';
import { translateTranslatableProp } from './../../pipes/translate-translatable-prop.pipe';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-themed-card',
  template: `
    @if (data) {
      <mat-card class="cursor-pointer px-5 my-3">
        <mat-card-header>
          <mat-card-title
            class="text-uppercase mx-auto"
            [ngStyle]="{ 'max-width:80%': showIcon }"
            style="font-size:20px; font-weight:700;">
            {{ title | async }}
          </mat-card-title>
          @if(description$ | async; as description) {
            <mat-card-subtitle class="my-2" [innerHTML]="description"> </mat-card-subtitle>
          }
        </mat-card-header>
        <mat-card-content>
          @if (showIcon) {
            <div class="fixed-icon" [matTooltip]="iconAndLabel.label">
              <div class="d-inline-flex">
                <span style="padding-top:2px">{{ iconAndLabel.label }}</span>
                <mat-icon class="ms-2 mb-2">{{ iconAndLabel.icon }}</mat-icon>
              </div>
            </div>
          }
          <!-- <div class="status-box" [matTooltip]="iconAndLabel.label"></div> -->
          @if (data.action) {
            <div
              (click)="$event.stopPropagation(); data.action.callback()"
              class="d-flex"
              style="color: #b8b8b8;position: absolute;bottom: 20px;">
              <a style="color:#b8b8b8;"><u>{{ actionLabel | async }}</u></a>
            </div>
          }
        </mat-card-content>
      </mat-card>
    }
  `,
  styles: [
    `
      :host {
        mat-card mat-card-title {
          max-width: 85%;
        }

        mat-card {
          min-height: 54px;
        }
      }

      .fixed-icon {
        position: absolute;
        font-size: smaller;
        right: 15px;
        top: 15px;
      }

      .status-box {
        position: absolute;
        top: 0;
        left: 0;
        writing-mode: vertical-lr;
        text-orientation: sideways-right;
        width: 10px;
        height: 100%;
      }
    `,
  ],
})
export class ThemedCardComponent {
  @Input() data!: {
    title: TranslatableValue | string;
    description?: TranslatableValue | string;
    completed?: boolean;
    current?: boolean;
    disabled?: boolean;
    action?: {
      label: TranslatableValue | string;
      callback: Function;
    };
  };

  @Input() truncate: boolean = true;
  @Input() showIcon: boolean = false;

  @Input() lang: 'it' | 'en' = 'it';

  constructor(private translateProp: translateTranslatableProp) {}

  get description$() {
    if (isString(this.data.description)) {
      return of(this.data.description);
    } else {
      this.translate(this.data.description as TranslatableValue).pipe(map(translation => this.truncate && translation ? translation.withEllipsis(300) : translation))
    }
  }

  get title(): Observable<string> {
    if (isString(this.data.title)) return of(this.data.title);
    return this.translateProp.transform(this.data.title, this.lang);
  }

  get actionLabel(): Observable<string> {
    if (isString(this.data.action?.label)) return of(this.data.action?.label);
    return this.translateProp.transform(this.data.action!.label ?? { it: '', en: '' }, this.lang);
  }

  get iconAndLabel(): { icon: string; label: string } {
    if (this.data.disabled) return { icon: 'lock', label: 'Disabilitato' };
    if (this.data.completed) return { icon: 'check_circle', label: 'Completato' };
    if (this.data.current) return { icon: 'mode_edit', label: 'In corso' };
    return { icon: 'edit_off', label: 'Incompleto' };
  }

  translate(value: TranslatableValue): Observable<string> {
    return this.translateProp.transform(value, this.lang);
  }
}
