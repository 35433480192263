import { Action, combineReducers } from '@ngrx/store';
import { authFeatureKey as _authFeatureKey, reducer as authReducer, AuthState as _AuthState } from './auth.reducer';
import { userTenantFeatureKey, UserTenantState, reducer as userTenantReducer } from './user-tenant.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
  [userTenantFeatureKey]: UserTenantState;
  [_authFeatureKey]: _AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [ userTenantFeatureKey]: userTenantReducer,
    [ _authFeatureKey ]: authReducer,
  })(state, action);
}
