import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { tenantFilterFeatureKey, State as TenantFilterState, reducer as tenantFilterReducer } from './tenant-filters.reducers';
import { tenantsFeatureKey, State as TenantsState, reducer as tenantReducer } from './tenants.reducer';
import { userFilterFeatureKey, State as UserFiltersState, reducer as tenantFiltersReducer } from './user-filters.reducers';
import { usersFeatureKey, State as UserState, reducer as userReducer } from './users.reducer';

export const managementFeatureKey = 'management';

export interface ManagementState {
  [usersFeatureKey]: UserState;
  [tenantFilterFeatureKey]: TenantFilterState
  [userFilterFeatureKey]: UserFiltersState;
  [tenantsFeatureKey]: TenantsState;
}

export function reducers(state: ManagementState | undefined, action: Action) {
  return combineReducers({
    [tenantsFeatureKey]: tenantReducer,
    [usersFeatureKey]: userReducer,
    [tenantFilterFeatureKey]: tenantFilterReducer,
    [userFilterFeatureKey]: tenantFiltersReducer,
  })(state, action);
}

export const selectManagementState = createFeatureSelector<ManagementState>(managementFeatureKey);

// TENANT CURRENT PAGE

export const selectTenantCurrentPage = createSelector(selectManagementState, state => state['tenants']);

export const selectUserCurrentPage = createSelector(selectManagementState, state => state['users']);

// TENANT FILTERS

export const selectTenantFilters = createSelector(selectManagementState, state => state['tenants-filter']);

export const selectTenantFilterSearch = createSelector(selectTenantFilters, state => state.search);

export const selectTenantFiltersFilters = createSelector(selectTenantFilters, state => state.filters);

// USER FILTERS

export const selectUserFilters = createSelector(selectManagementState, state => state['user-filter']);

export const selectUserFilterSearch = createSelector(selectUserFilters, state => state.search);

export const selectUserFilterFilters = createSelector(selectUserFilters, state => state.filters);
