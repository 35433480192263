import { createReducer, on } from '@ngrx/store';
import { resetUserFilters, updateTextSearchFilter, updateUserFilters } from '../actions/user-filters.actions';

export const userFilterFeatureKey = 'user-filter';

export interface State {
  filters: {
    roles: string[] | null;
    tenants: string[] | null;
    status: {
      enabled: boolean;
      disabled: boolean;
    };
  };
  search: {
    value: string;
  };
}

const initialState: State = {
  filters: {
    roles: null,
    tenants: null,
    status: {
      enabled: true,
      disabled: false,
    },
  },
  search: {
    value: '',
  },
};

export const reducer = createReducer(
  initialState,
  on(updateUserFilters, (state, { roles, tenants, status }): State => ({
    ...state,
    filters: { roles, tenants, status },
  })),
  on(updateTextSearchFilter, (state, { value }): State => ({
    ...state,
    search: { value },
  })),
  on(resetUserFilters, (): State => initialState)
);
