import { Attribute, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Mongo } from '@core/models/mongo';
import { map, Observable, startWith, Subscription } from 'rxjs';

type IdAndLabel = { _id: Mongo.ObjectId; label: string };

type ItemType = IdAndLabel | string;

@Component({
  selector: 'app-searchable-select[items]',
  template: `
    <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
      <mat-label>{{ 'searchable-select.component.azienda' | translate }}</mat-label>
      <mat-select [multiple]="multiple" [formControl]="selectCtrl">
        <mat-option>
          <ngx-mat-select-search
            noEntriesFoundLabel="Nessun risultato"
            [placeholderLabel]="placeholderLabel"
            [formControl]="searchCtrl"></ngx-mat-select-search>
        </mat-option>
        @for (item of filteredItems$ | async; track item) {
          <mat-option [value]="item">
            {{ item }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableSelectComponent),
      multi: true,
    },
  ],
})
export class SearchableSelectComponent<T extends ItemType> implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() items: T[] = [];

  value: T | T[] | null = null;

  searchCtrl = new UntypedFormControl('');

  selectCtrl = new UntypedFormControl();

  filteredItems$: Observable<T[]> = this.searchCtrl.valueChanges.pipe(
    startWith(''),
    map((searchValue: string) => {
      if (!searchValue) return this.items;
      return this.items.filter(item => {
        if (typeof item === 'string') {
          return item.startsWith(searchValue);
        } else if (this.isIdAndLabel(item)) {
          return item.label.startsWith(searchValue);
        } else {
          throw new Error('item is not of type string or IdAndLabel');
        }
      });
    })
  );

  private sub = new Subscription();

  private onChange: any = () => {};

  constructor(
    @Attribute('placeholderLabel') public placeholderLabel: string,
    @Attribute('multiple') public multiple: boolean = false
  ) {}

  ngOnInit(): void {
    const valueChangeSub = this.selectCtrl.valueChanges.subscribe(value => {
      this.onChange(value);
    });

    this.sub.add(valueChangeSub);
  }

  writeValue(value: T | T[]): void {
    this.selectCtrl.setValue(value);
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  isIdAndLabel(value: any): value is IdAndLabel {
    return value && value._id && value.label;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
