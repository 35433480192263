import { createReducer, on } from '@ngrx/store';
import { resetTenantFilter, updateStateFilter, updateTextSearchFilter } from '../actions/tenant-filters.action';

export const tenantFilterFeatureKey = 'tenants-filter';

export interface State {
  filters: {
    status: {
      enabled: boolean;
      disabled: boolean;
    };
  };
  search: {
    value: string;
  };
}

const initialState: State = {
  filters: {
    status: {
      enabled: true,
      disabled: false,
    },
  },
  search: {
    value: '',
  },
};

export const reducer = createReducer(
  initialState,
  on(resetTenantFilter, (): State => initialState),
  on(updateStateFilter, (state: State, { enabled, disabled }): State => ({
    ...state,
    filters: { status: { enabled, disabled } },
  })),
  on(updateTextSearchFilter, (state, { value }): State => ({ ...state, search: { value } }))
);
