import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { MgmtTenantService } from 'src/app/admin/management/services/mgmt-tenant.service';
import { selectUserId } from 'src/app/auth/state/selectors/auth.selectors';
import { Tenant } from 'src/app/core/models/tenant';
import { User } from 'src/app/core/models/users';
import { UsersService } from 'src/app/core/services/users.service';
import { updateTextSearchFilter } from 'src/app/core/store/management/actions/user-filters.actions';
import { updateUserCurrentPage } from 'src/app/core/store/management/actions/user.actions';
import { selectUserCurrentPage, selectUserFilterFilters, selectUserFilters } from 'src/app/core/store/management/reducers';
import { ConfirmActionComponent } from 'src/app/shared/components/confirm-action/confirm-action.component';
import { escapeAndEncodeRegexFilterValue, escapeFilterValueNoRegex } from 'src/app/shared/utils/shared';
import { EditUserDetailsDialogComponent } from '../edit-user-details-dialog/edit-user-details-dialog.component';
import { InviteUserDialogComponent } from './user-invite-dialog.component';
import { UserPropsFilterDialogComponent } from './user-props-filter-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-management',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersManagementComponent implements OnInit, OnDestroy {
  @Input() isTenantFilterEnabled: boolean = true;

  tableCols = ['_id', 'tenant', 'name', 'surname', 'roles', 'phone', 'action'];

  users$: BehaviorSubject<User.User[]> = new BehaviorSubject<User.User[]>([]);

  readonly pageSize = 10;

  canResendEmail = true;

  userCollectionSize!: number;

  isPaginatorDisabled = true;

  userList$!: Observable<User.User[]>;

  searchProps = ['_id', 'profile.surname', 'profile.name'];

  filterQuery$ = new BehaviorSubject<string>('');

  currentUser$ = this.store.select(selectUserId);

  @ViewChild('paginator') paginator!: MatPaginator;

  sub = new Subscription();

  filterDialogDirty: boolean = false;

  constructor(
    private userSvc: UsersService,
    public inviteFormDialog: MatDialog,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private store: Store,
    private tenantSvc: MgmtTenantService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const queryParamsSub = this.resolveFiltersToQueryString().subscribe();
    this.sub.add(queryParamsSub);

    this.userList$ = combineLatest([this.filterQuery$, this.store.select(selectUserCurrentPage)]).pipe(
      switchMap(([filters, currentPage]) =>
        forkJoin([
          this.userSvc.getPage(currentPage.pageIndex + 1, this.pageSize, filters),
          this.userSvc.getUserCollectionSize(filters),
          of(currentPage),
        ])
      ),
      tap(([users, collectionSize, { pageIndex }]) => {
        this.users$.next(users);
        this.userCollectionSize = collectionSize;

        this.paginator.pageIndex = pageIndex;
        this.paginator.length = collectionSize;
        this.paginator.pageSize = this.pageSize;
        this.isPaginatorDisabled = false;
      }),
      switchMap(([users]) => of(users))
    );

    const s = this.store
      .select(selectUserFilterFilters)
      .pipe(
        take(1),
        tap(f => {
          this.filterDialogDirty = !f.status.enabled || f.status.disabled || (f.tenants !== null && f.tenants?.length > 0) || (f.roles !== null && f.roles?.length > 0);
        })
      )
      .subscribe();

      this.sub.add(s);
  }

  onFilterQueryChange(query: string) {
    this.filterQuery$.next(query);
  }

  onSearchChange(value: string) {
    //this.store.dispatch(updateUserCurrentPage({pageIndex: 0, pageSize: 10, length: 0}));
    this.store.dispatch(updateTextSearchFilter({ value }));
  }

  getNextPage(event: PageEvent) {
    this.store.dispatch(updateUserCurrentPage(event));
  }

  resendEmail(userId: string) {
    this.userSvc.sendEmail(userId).subscribe({
      next: () => {
        this.snackbar.open(this.translate.instant('users.component.lemail-e-stata-invia'), 'x', { duration: 3000 });
        this.filterQuery$.next(this.filterQuery$.value)
      },
      error: err => console.error('[Management/User] Failed to sent email', err),
    });
  }

  resetOrActivationLink(user: User.User) {
    const baseAppUrl = window.location.origin;

    const roles = user.roles;
    const email = user._id;
    const otp = user.pwdAuth?.code;
    const cg = user.consents ? "true": "false"

    return roles.includes(User.UserRole.LOCKED_USER)
      ? `${baseAppUrl}/signin/activate?otp=${otp}&email=${email}&cg=${cg}`
      : `${baseAppUrl}/signin/reset?otp=${otp}&email=${email}`;
  }

  openConfirmationDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmActionComponent);
    return dialogRef.afterClosed();
  }

  joinRoles(roles: User.UserRole[]) {
    return combineLatest(
      roles.map(role => {
        if (role.match('locked-user')) {
          return this.translate.get(`ROLES.LOCKED_USER`);
        } else {
          return this.translate.get(`ROLES.${role.toUpperCase()}`);
        }
      })
    ).pipe(
      switchMap(values => {
        return of(values.join(','));
      })
    );
  }

  isLockedUser(roles: User.UserRole[]): boolean {
    return roles.some(role => role.match('locked-user'));
  }

  inviteUser(): void {
    const dialogRef = this.inviteFormDialog.open(InviteUserDialogComponent, {
      data: {
        tenants$: this.tenantSvc.getAll().pipe(map((tenants: Tenant.Tenant[]) => tenants.map(t => t._id))),
      },
    });

    const sub = dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.userSvc.inviteUser(data)),
        switchMap(() => this.filterQuery$),
        switchMap(filters =>
          this.userSvc.getPage(1, this.pageSize, filters).pipe(
            tap((users: User.User[]) => {
              this.store.dispatch(updateUserCurrentPage({ pageIndex: 0, pageSize: 10, length: 0 }));
              this.users$.next(users);
              this.paginator.firstPage();
            })
          )
        ),
        catchError((err: HttpErrorResponse) => {
          this.translate
            .get(['users.component.esiste-gia-un-utente', 'users.component.abbiamo-riscontrato-'])
            .subscribe(msgs => {
              if (err.status === 409) {
                this.snackbar.open(msgs['users.component.esiste-gia-un-utente'], 'x', { duration: 5000 });
                console.error(err);
              } else {
                this.snackbar.open(msgs['users.component.abbiamo-riscontrato-'], 'x', { duration: 3000 }),
                  console.error('[Management/Users] Error while trying to invite user', err);
              }
            });

          return EMPTY;
        })
      )
      .subscribe();

    this.sub.add(sub);
  }

  // After successfully editing a user, reload the current page
  openEditDialog(user: User.User) {
    this.dialog
      .open(EditUserDetailsDialogComponent, {
        data: { user },
      })
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => {
          if (data.action === 'delete') {
            return of(null);
          } else {
            return this.userSvc.update(user._id, data.payload);
          }
        }),
        switchMap(() => this.userSvc.getPage(1, this.pageSize, this.filterQuery$.getValue())),
        tap((users: User.User[]) => this.users$.next(users))
      )
      .subscribe({
        next: () =>
          this.translate
            .get('users.component.i-dati-dellutente-so')
            .subscribe(msg => this.snackbar.open(msg, 'x', { duration: 3000 })),
        error: () =>
          this.translate
            .get('users.component.abbiamo-riscontrato--0')
            .subscribe(msg => this.snackbar.open(msg, 'x', { duration: 3000 })),
      });
  }

  openUserPropsFilterDialog() {
    const dialogRef = this.dialog.open(UserPropsFilterDialogComponent);
    dialogRef.componentInstance.isTenantFilterEnabled = this.isTenantFilterEnabled;
    const s = dialogRef.componentInstance.dirty.subscribe(d => this.filterDialogDirty = d);
    this.sub.add(s);
  }

  resolveFiltersToQueryString() {
    return this.store.select(selectUserFilters).pipe(
      switchMap(({ filters, search }) => {
        const { roles, status, tenants } = filters;
        const { enabled, disabled } = status;
        let query = [];

        if (search?.value?.length) {
          const ref = escapeAndEncodeRegexFilterValue(search.value);
          query.push(
            `filter={"$or":[{"_id":{"$regex":"${ref}", "$options":"i"}}, {"profile.name":{"$regex":"${ref}", "$options":"i"}},{"profile.surname":{"$regex":"${escapeAndEncodeRegexFilterValue(search.value)}", "$options":"i"}} ]}`
          );
        }

        if (enabled !== disabled) {
          query.push(`filter={"enabled": ${enabled === true}}`);
        }

        if (roles?.length) {
          const vals = roles.map(role => `"${role}"`).join(',');
          query.push(`filter={"roles":{"$in": [${vals}]}}`);
        }

        if (tenants?.length) {
          const vals = tenants.map(tenant => `"${escapeFilterValueNoRegex(tenant)}"`).join(',');
          query.push(`filter={"tenants":{"$in":[${vals}]}}`);
        }

        return of(query.join('&'));
      }),
      tap(query => this.filterQuery$.next(query))
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
