import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, Subscription, debounceTime, distinctUntilChanged, take, tap } from 'rxjs';
import { selectTenantFilterSearch, selectUserFilterSearch } from 'src/app/core/store/management/reducers';

@Component({
  selector: 'app-text-search-filter[context]',
  template: `
    <mat-form-field class="hello" appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ 'text-search-filter.component.cerca' | translate }}</mat-label>
      <input matInput [formControl]="search" />
      <mat-icon
        [ngStyle]="{ opacity: search.value ? 1 : 0 }"
        matSuffix
        (click)="search.reset()"
        style="cursor: pointer">
        clear
      </mat-icon>
    </mat-form-field>
  `,
  styles: [],
})
export class TextSearchFilterComponent implements OnInit, OnDestroy {
  @Output() searchTextChange = new EventEmitter<string>();

  @Input() context!: 'tenant' | 'user' | 'archive';
  @Input() reset!: Subject<void>;

  search = new UntypedFormControl('');

  subs: Subscription[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    // Initialize formControl value
    if (this.context === 'tenant' || this.context === 'user') {
    this.store
      .select(this.context === 'tenant' ? selectTenantFilterSearch : selectUserFilterSearch)
      .pipe(take(1))
      .subscribe(({ value }) => this.search.setValue(value));
    }

    // on form control value change, update store
    this.subs.push(
      this.search.valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(value => this.searchTextChange.emit(value))
        )
        .subscribe()
    );

    if (this.reset) this.subs.push(this.reset.subscribe(() => this.search.reset()));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub?.unsubscribe());
  }
}
