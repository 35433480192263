import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-upload-file-dialog',
  template: `
    <section>
      <h3 mat-dialog-title class="text-center">
        {{ data && data.title ? (data.title | translate) : ('upload-file-dialog.carica-un-nuovo-file' | translate) }}
      </h3>
      <div class="d-flex flex-column align-items-center justify-content-center">
        @if (currentImage$ | async; as currentImage) {
          <div class="image-preview">
            <img crossorigin [src]="currentImage" />
          </div>
        }
        <div class="my-3 d-flex justify-content-center">
          <button color="primary" mat-fab (click)="resetFile(); uploader.click()" matTooltip="Seleziona un file">
            <mat-icon> file_upload </mat-icon>
          </button>
        </div>
        <input #uploader type="file" hidden [accept]="accept" (change)="onFileChange($event)" />
      </div>
      <section class="d-flex justify-content-center" mat-dialog-actions>
        <button mat-flat-button mat-dialog-close>{{ 'upload-file-dialog.annulla' | translate }}</button>
        <button mat-raised-button [disabled]="!file" color="primary" [mat-dialog-close]="file" class="ms-3">
          {{ 'upload-file-dialog.carica' | translate }}
        </button>
      </section>
    </section>
  `,
  styles: [
    `
      :host {
        display: block;
        min-width: 500px;
      }

      .image-preview img {
        object-fit: cover;
        min-width: 350px;
        max-width: 400px;
        min-height: 200px;
        max-height: 250px;
      }
    `,
  ],
})
export class UploadFileDialogComponent implements OnInit {
  file: File | null = null;

  @ViewChild('uploader') uploader: any;

  accept = '*';

  currentImage$ = new BehaviorSubject<string | null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resource: string;
      imagePreviewUrl?: string;
      resourceName?: string;
      title?: string;
      accept?: string;
    }
  ) {}

  ngOnInit(): void {
    if (this.data.imagePreviewUrl) {
      this.currentImage$.next(this.data.imagePreviewUrl);
    }

    this.accept = this.data.accept ?? '*';
  }

  resetFile() {
    this.file = null;
    this.uploader.nativeElement.value = '';
  }

  async onFileChange(data: Event) {
    const element = data.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.file = fileList[0];
      const base64 = await this.fileToBase64(this.file);
      this.currentImage$.next(base64);
    }
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }
}
