import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@core/models/users';
import { rolesMap } from '../utils/roles-map';

@Pipe({
  name: 'remapRole',
})
export class RemapRolePipe implements PipeTransform {
  transform(value: User.UserRole, ...args: unknown[]): { it: string; en: string; pt: string } {
    return rolesMap.get(value) ?? { it: 'role-not-found', en: 'role-not-found', pt: 'role-not-found' };
  }
}
